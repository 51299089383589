<template>
  <div>
    <div class="row">
      <div class="col-12">
        <c-card title="LBLBASEINFO" class="cardClassDetailInfo">
          <template slot="card-detail">
            <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
              <c-label-text title="저장시설" value="탱크1"></c-label-text>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
              <c-label-text title="저장시설 위치" value="위치1"></c-label-text>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
              <c-label-text title="총 허가량" value="1300kg"></c-label-text>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
              <c-label-text title="허가량 대비 재고량 비율" value="60%"></c-label-text>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-table
      class="q-mt-sm"
      title="위험물 허가량 및 비율"
      tableId="table"
      :columns="grid1.columns"
      :data="grid1.data"
      gridHeight="300px"
      :filtering="false"
      :columnSetting="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addDangerPermit" />
          <c-btn v-if="editable" label="LBLREMOVE" icon="remove" />
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      class="q-mt-sm"
      title="화학자재 재고량"
      tableId="table"
      :columns="grid2.columns"
      :data="grid2.data"
      :gridHeight="gridHeight"
      :filtering="false"
      :columnSetting="false"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'store-danger-permit-detail',
  components: {
  },
  props: {
    contentHeight: {
      type: [Number, String],
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      grid1: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '위험물',
            child: [
              {
                name: 'col1',
                field: 'col1',
                label: '유별',
                align: 'center',
                sortable: true,
                style: 'width:100px',
              },
              {
                name: 'col2',
                field: 'col2',
                label: '성질',
                align: 'center',
                sortable: true,
                style: 'width:100px',
              },
              {
                name: 'col3',
                field: 'col3',
                label: '품명',
                align: 'left',
                sortable: true,
                style: 'width:200px',
              },
            ]
          },
          {
            name: 'col4',
            field: 'col4',
            label: '지정수량',
            align: 'right',
            sortable: true,
            style: 'width:150px',
          },
          {
            name: 'col5',
            field: 'col5',
            label: '허가량 대비 비율(%)',
            align: 'right',
            sortable: true,
            style: 'width:150px',
            type: 'cost'
          },
        ],
        data: [
          { col1: '제1류', col2: '산화성 고체', col3: '1. 아염소산염류', col4: '50kg', col5: '43' },
          { col1: '제1류', col2: '산화성 고체', col3: '3. 과염소산염류', col4: '50kg', col5: '72' },
          { col1: '제3류', col2: '자연발화성 물질 및 금수성 물질', col3: '5. 황린', col4: '20kg', col5: '76' },
        ],
      },
      grid2: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            style: 'width:120px',
            sortable: true,
            type: 'link'
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'materialNameEn',
            field: 'materialNameEn',
            label: '화학자재명(ENG)',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'deliveryVendorName',
            field: 'deliveryVendorName',
            label: '공급업체',
            align: 'left',
            style: 'width:120px',
            sortable: true,
          },
          {
            label: '2022년',
            child: [
              {
                label: '입고',
                name: '2022_in',
                field: '2022_in',
                align: 'right',
                sortable: false,
                style: 'width:60px',
                type: 'cost'
              },
              {
                label: '출고',
                name: '2022_out',
                field: '2022_out',
                align: 'right',
                sortable: false,
                style: 'width:60px',
                type: 'cost'
              },
            ],
          },
          {
            label: '2021년',
            child: [
              {
                label: '입고',
                name: '2021_in',
                field: '2021_in',
                align: 'right',
                sortable: false,
                style: 'width:60px',
                type: 'cost'
              },
              {
                label: '출고',
                name: '2021_out',
                field: '2021_out',
                align: 'right',
                sortable: false,
                style: 'width:60px',
                type: 'cost'
              },
            ],
          },
          {
            label: '2020년',
            child: [
              {
                label: '입고',
                name: '2020_in',
                field: '2020_in',
                align: 'right',
                sortable: false,
                style: 'width:60px',
                type: 'cost'
              },
              {
                label: '출고',
                name: '2020_out',
                field: '2020_out',
                align: 'right',
                sortable: false,
                style: 'width:60px',
                type: 'cost'
              },
            ],
          },
        ],
        data: [],
      },
      popupOptions: {
        width: '90%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 360) + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url 
      this.listUrl = selectConfig.mdm.mam.chem.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: '1000'
      }
      this.$http.request((_result) => {
        let from = 2020;
        let to = 2022;
        this.$_.forEach(_result.data, item => {
          item.deliveryVendorName = '업체' + this.$comm.generateRandom(1, 5)
          let in_sum = 0;
          let out_sum = 0;
          for (let i = from; i <= to; i++) {
            item[i + '_in'] = this.$comm.generateRandom(10, 300)
            item[i + '_out'] = this.$comm.generateRandom(10, 300)
            in_sum += item[i + '_in']
            out_sum += item[i + '_out']
          }
          item['in_sum'] = in_sum
          item['out_sum'] = out_sum
        })
        this.grid2.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '화학자재 상세';
      this.popupOptions.param = {
        mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
        plantCd: row ? row.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    addDangerPermit() {
      this.popupOptions.title = '위험물 상세';
      this.popupOptions.target = () => import(`${'@/pages/chm/base/dangerItemPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDangerPopup;
    },
    closeDangerPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid1.data, { col3: item.col3 }) === -1) {
            item.col5 = this.$comm.generateRandom(40, 85)
            this.grid1.data.push(item);
          }
        })
      }
    },
  }
};
</script>